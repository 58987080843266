import React, { createContext, useContext, useState } from "react";

const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [selectedTeamName, setSelectedTeamName] = useState(null);
  const [clickWithOrg, setClickWithOrg] = useState(false);
  const [clickWithPage, setClickWithPage] = useState(false);
  const setTeamName = (teamName) => {
    setSelectedTeamName(teamName);
  };
  const setTeamId = (teamId) => {
    setSelectedTeamId(teamId);
  };
  const setToClickWithOrg = (bool) => {
    setClickWithOrg(bool);
  };
  const setToClickWithPage = (bool) => {
    setClickWithPage(bool);
  };

  return (
    <TeamContext.Provider
      value={{
        selectedTeamId,
        setTeamId,
        setTeamName,
        selectedTeamName,
        setToClickWithOrg,
        clickWithOrg,
        setToClickWithPage,
        clickWithPage,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeam = () => {
  return useContext(TeamContext);
};
