/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-13 19:21:12
 */
import axios from "axios";
import { Url, siteConstant } from "src/config";
import CryptoJS from 'crypto-js';
/**
 * Login API Service
 * @date 6/28/2023 - 3:13:52 AM
 * pass credentials
 */

const loginApiService = {
  checkLogin: async () => {
    try {
      console.log("credentials");
      const response = await axios.post(`${Url}/module/profile`);
      console.log("Response", response);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getProvider: async (data) => {
    //Get sso-provider
    try {
      console.log("get sso Provider ");
      const response = await axios.post(`${Url}/auth/sso-provider`, data);
      console.log("Response", response);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getDashboardIds: async (accessToken, id) => {
    try {
      console.log("tokeeeeeen",accessToken);
      const response = await axios.get(`${Url}/customer/getdashboardperms?userId=${id}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(response.data.dashIds), id.toString()).toString();
      console.log("dashboardPerms",response.data.dashIds);
      // sessionStorage.setItem("dashboardPerms", JSON.stringify(response.data.dashIds));
      sessionStorage.setItem("dashboardPerms", encrypted);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getSurveyIds: async (accessToken, id) => {
    try {
      console.log("tokeeeeeen",accessToken);
      const response = await axios.get(`${Url}/customer/getsurveyperms?userId=${id}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(response.data.surveyIds), id.toString()).toString();
        console.log("surveyPerms",response.data.surveyIds);
        // sessionStorage.setItem("surveyPerms", JSON.stringify(response.data.surveyIds));
        sessionStorage.setItem("surveyPerms", encrypted);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  acceptUserConsent: async (data) => {
    //Accept user consent
    try {
      const response = await axios.post(`${Url}/auth/user-consent`, data);
      const token = response.headers["x-access-token"];
      sessionStorage.setItem(siteConstant.AUTH_TOKEN_STORAGE_KEY, token);
      console.log("Response", response);
      return;
    } catch (error) {
      console.log(error.message);
    }
  },
};


export default loginApiService;
