/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-25 20:48:42
 */
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { createTypography } from "src/theme/base/customTypography";
import { ReactComponent as PlusBUtton } from "src/icons/untitled-ui/+ButtonWhite.svg";
import {
  Checkbox,
  Typography,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useUser } from "src/hooks/use-user";
import { useEffect } from "react";
// import teamDashboardResultsApi from "src/services/teamdashboardApi";
import mtpDashboardResultsApi from "src/services/mtpDashboardApi";
import { useState } from "react";
import { useTeam } from "src/contexts/mtp-team-context";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Scrollbar } from "src/components/scrollbar";
import { toast } from "react-hot-toast";
import * as SldButtons from "src/theme/base/SldButtons";

export default function TemporaryDrawer() {
  const typography = createTypography();
  const user = useUser();
  const accessToken = sessionStorage.getItem("accessToken");
  const [isLoading, setIsLoading] = useState(true);
  const {
    selectedTeamId,
    setTeamId,
    removeTeamId,
    applySelectedTeams,
    unApplySelectedTeams,
    teamInfo,
    removeAllTeamIds,
    settingOrgLevelStateTrue,
    settingOrgLevelStateFalse,
    settingOrgName,
    orgLevel
  } = useTeam();

  // const [selectedDrawerTeamIds, setSelectedDrawerTeamIds] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [directTeam, setDirectTeam] = useState([]);
  const [organisationName, setOrganisationName] = useState();
  const [teamsUnderDivision, setTeamsUnderDivision] = useState();
  console.log("teamsList", teamsList);
  console.log("teamsUnderDivision", teamsUnderDivision);
  console.log("teamInfo", teamInfo);
  console.log("directTeam", directTeam);
  const [selectedDivisionIds, setSelectedDivisionIds] = useState([]);

  const fetchUsersTeams = async () => {
    try {
      const response = await mtpDashboardResultsApi.getUserTeamTree(
        accessToken,
        user.id
      );
      if (response.message) {
        toast.error(response.message)
        setIsLoading(false);
      } else {
        console.log("came here in else condition");
        console.log("response teams", response);
        // console.log("response teamTree", response.teamTree);
        // console.log("response teamWithOutDivision", response.teamWithOutDivision);
        // console.log("organisationName", response.organisationName[0]);
        setTeamsList(response.teamTree);
        setDirectTeam(response.teamWithOutDivision);
        setOrganisationName(response.organisationName[0]);
        settingOrgName(response.organisationName[0].OrganisationName);
        if (response.teamTree.length > 0) {
          setTeamsUnderDivision(response.teamTree);
        }
        setIsLoading(false);
      }

    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchUsersTeams();
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (directTeam) {
      console.log("directTeam", directTeam);
    }

  }, [directTeam])

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    // Check if the click event originated from within a checkbox
    if (event.target.tagName === "INPUT" && event.target.type === "checkbox") {
      return;
    }

    setState({ ...state, [anchor]: open });
  };



  const handleSubmit = () => {
    applySelectedTeams();
  };

  useEffect(() => {
    if (selectedTeamId) {
      console.log("selectedTeamId", selectedTeamId);
    }
  }, [selectedTeamId]);

  const handleTeamCheckboxChange = (teamId, teamName, divisionId, divisionName) => {
    console.log("divisionId", divisionId);
    settingOrgLevelStateFalse();
    if (selectedTeamId.includes(teamId)) {
      // If the teamId is already selected, remove it
      removeTeamId(teamId);
      unApplySelectedTeams();
    } else {
      // Otherwise, add it to the selectedTeamIds
      setTeamId(teamId, teamName, divisionName);
      unApplySelectedTeams();
    }

    // Logic to update division checkbox state based on selected teams
    updateDivisionCheckboxState(divisionId);
  };

  const updateDivisionCheckboxState = (divisionId) => {
    console.log("----came here----", divisionId);
    const teamsInDivision = teamsList.find((division) => division.division_id === divisionId)?.teams;
    if (teamsInDivision) {
      let allTeamsSelected = true;
      let allTeamsUnselected = true;

      teamsInDivision.forEach((team) => {
        if (!selectedTeamId.includes(team.team_category_id)) {
          allTeamsSelected = false;
        } else {
          allTeamsUnselected = false;
        }
      });

      // Update division checkbox state
      const divisionCheckbox = document.getElementById(`division-checkbox-${divisionId}`);
      if (allTeamsSelected) {
        divisionCheckbox.indeterminate = false;
        divisionCheckbox.checked = true;
      } else if (allTeamsUnselected) {
        divisionCheckbox.indeterminate = false;
        divisionCheckbox.checked = false;
      } else {
        divisionCheckbox.indeterminate = true;
      }
    }
  };

  const handleOrgCheckboxChange = () => {
    settingOrgLevelStateTrue();
    // Transform teamsUnderDivision data
    console.log("teamsUnderDivision", teamsUnderDivision);
    let teamsUnderDivisionData = [];
    let directTeamData = [];
    if (teamsUnderDivision !== undefined) {
      
      teamsUnderDivisionData = teamsUnderDivision?.flatMap(division =>
        division.teams.map(team => ({
          teamId: team.team_category_id,
          teamName: team.team_name,
          divisionName: division.division_name
        }))
      );
    } else {

      directTeamData = directTeam.map(team => ({
        teamId: team.team_category_id,
        teamName: team.team_name,
        divisionName: '', // Replace 'Division Name' with the actual division name
      }));
    }
    const updatedTeamId = [...teamsUnderDivisionData, ...directTeamData];

    // Transform directTeam data


    // Concatenate the transformed data and update setTeamId state

    // Log the updated setTeamId state
    console.log("updatedTeamId", updatedTeamId);

    // Function to insert data into setTeamId context function
    const insertDataIntoSetTeamIdContext = (data) => {
      data.forEach(({ teamId, teamName, divisionName }) => {
        setTeamId(teamId, teamName, divisionName);
      });
    };


    if (updatedTeamId && updatedTeamId.length > 0) {
      removeAllTeamIds();
      insertDataIntoSetTeamIdContext(updatedTeamId);
    }
    handleSubmit();
    setState({ ...state, left: false });
  };

  const handleDivisionCheckboxChange = (divisionId, divisionName) => (event) => {
    const { checked } = event.target;

    // Logic to handle selecting/deselecting teams within a division
    const teamsInDivision = teamsList.find((division) => division.division_id === divisionId)?.teams;
    if (teamsInDivision) {
      if (checked) {
        teamsInDivision.forEach((team) => {
          if (!selectedTeamId.includes(team.team_category_id)) {
            setTeamId(team.team_category_id, team.team_name, divisionName);
            unApplySelectedTeams();
          }
        });
        setSelectedDivisionIds((prevIds) => [...prevIds, divisionId]);
      } else {
        teamsInDivision.forEach((team) => {
          removeTeamId(team.team_category_id);
          unApplySelectedTeams();
        });
        setSelectedDivisionIds((prevIds) => prevIds.filter((id) => id !== divisionId));
      }
    }

    // Update division checkbox state
    updateDivisionCheckboxState(divisionId);
  };


  const ChildTeams = ({
    teams,
    divisionId,
    divisionName,
    handleTeamCheckboxChange,
    selectedTeamIds,
  }) => (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {teams.map((team, index) => (
        <FormControlLabel
          key={index}
          label={
            <Typography
              sx={{
                ...typography.subtitle.regularColorless,
                color: "white",
              }}
              pl={2}
            >
              {team.team_name}
            </Typography>
          }
          control={
            <Checkbox
              checked={selectedTeamIds.includes(team.team_category_id)}
              onChange={(event) => {
                handleTeamCheckboxChange(team.team_category_id, team.team_name, divisionId, divisionName);
              }}
            />
          }
        />
      ))}
    </Box>
  );

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        height: "100%",
        backgroundColor: "#583F99",
        borderRadius: '0 15px 15px 0',
        zIndex: 1201,
        paddingLeft: '45px',
        paddingTop: '25px'

      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      p={2}
    >
      <Typography
        sx={{ ...typography.subtitle.regularColorless, color: "white" }}
      >
        Multi-Team Selector
      </Typography>
      <Scrollbar style={{ maxHeight: '100%', width: '100%' }}>
        {organisationName && (
          <List>

            <div >
              <Accordion
                sx={{ backgroundColor: "transparent" }}>
                <AccordionSummary

                >
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          ...typography.subtitle.regularColorless,
                          color: "white",
                        }}
                      >
                        {organisationName.OrganisationName}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={orgLevel}
                        onChange={(event) => {
                          handleOrgCheckboxChange();
                        }}
                      />
                    }
                  />
                </AccordionSummary>

              </Accordion>
            </div>

          </List>
        )}
        <List>
          {teamsList && teamsList.length > 0 && (
            <>
              {teamsList.map((division, index) => (
                <div key={index}>
                  <Accordion
                    sx={{ backgroundColor: "transparent" }}>
                    <AccordionSummary
                      expandIcon={<PlusBUtton />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <FormControlLabel
                        label={
                          <Typography
                            sx={{
                              ...typography.subtitle.regularColorless,
                              color: "white",
                            }}
                          >
                            {division.division_name}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            id={`division-checkbox-${division.division_id}`}
                            checked={selectedDivisionIds.includes(
                              division.division_id
                            )}
                            onChange={handleDivisionCheckboxChange(
                              division.division_id,
                              division.division_name
                            )}
                          />
                        }
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <ChildTeams
                        teams={division.teams}
                        divisionId={division.division_id}
                        divisionName={division.division_name}
                        handleTeamCheckboxChange={handleTeamCheckboxChange}
                        selectedTeamIds={selectedTeamId}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </>
          )}
        </List>
        <List>
          {directTeam && directTeam.length > 0 && (
            <>
              {directTeam.map((team, index) => (
                <div key={index}>
                  <Accordion
                    sx={{ backgroundColor: "transparent" }}>
                    <AccordionSummary

                    >
                      <FormControlLabel
                        label={
                          <Typography
                            sx={{
                              ...typography.subtitle.regularColorless,
                              color: "white",
                            }}
                          >
                            {team.team_name}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            id={`division-checkbox-${team.team_category_id}`}
                            checked={selectedTeamId.includes(team.team_category_id)}
                            onChange={(event) => {
                              handleTeamCheckboxChange(team.team_category_id, team.team_name);
                            }}
                          />
                        }
                      />
                    </AccordionSummary>

                  </Accordion>
                </div>
              ))}
            </>
          )}
        </List>
      </Scrollbar>
      <div style={{ alignSelf: "center" }}>
        <SldButtons.WhiteButtonCap onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ position: 'absolute', bottom: '15px', right: '15px' }}>
          Apply
        </SldButtons.WhiteButtonCap>
      </div>
    </Box>
  );

  React.useEffect(() => {
    // Open the drawer directly when the component mounts
    setState((prev) => ({ ...prev, left: true }));
  }, []);

  // useEffect(() => {
  //   if (teamsList) {
  //     setIsLoading(false);
  //   }
  // }, [teamsList]);

  //loader animation will run until response fetched from backend
  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={state["left"]}
      onClose={toggleDrawer("left", false)}

      sx={{

        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0)!important', // Set the background color of the backdrop to transparent

        },
        '& .MuiPaper-root.MuiPaper-elevation': { left: '210px', zIndex: -1, overflow: 'hidden' },
        '& .MuiPaper-root.MuiPaper-elevation .MuiPaper-root.MuiPaper-elevation': { left: 0, zIndex: 1, },
        '& .MuiButtonBase-root': {

          '& rect': {
            fill: '#fff',
            stroke: '#fff;'
          }

        },
        '& .MuiAccordionDetails-root .MuiBox-root': { marginLeft: 0 },
        '& .MuiAccordionSummary-root': { borderBottom: '1px solid rgba(255,255,255,.3)', borderTop: '1px solid rgba(255,255,255,.3)' },
        '& .MuiAccordionSummary-content': { margin: '2px 0!important' },
        '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: '25px', margin: '2px 0' },
        '& .MuiButtonBase-root.MuiCheckbox-root': { padding: '4px' },
        '& .MuiFormControlLabel-root .MuiTypography-root': { paddingLeft: 0 },


      }}

    >
      {list("left")}
    </Drawer>
  );
}
