import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import * as SldButtons from "src/theme/base/SldButtons";
import { useLocation } from 'react-router-dom';
import { purple } from "src/theme/colors";





const DashboardMenu = () => {
  const [dbmenu, setDbmenu] = useState('');
  const navigate = useNavigate();

  const handleNavigate = () => {
   
    navigate("/dashboard");
  };
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setDbmenu(selectedValue);
    
    // Navigate based on the selected value
  switch (selectedValue) {
      case 10:
        navigate('/leadSelf');
        break;
      case 20:
        navigate('/teamMojo');
        break;
      case 30:
        navigate('/MTP');
        break;
      default:
        break;
    }
  };
  const location = useLocation();

  return (
    <>
    <ul 
    style={{listStyle:'none', margin:'0', padding:'0',  }}>
      <li>
      <SldButtons.NavbarButton 
      onClick={handleNavigate} 
      className={location.pathname === '/dashboard' ? 'active' : ''}
      sx={{'&.MuiButtonBase-root.active path': { fill: 'white' },
    '&.MuiButtonBase-root.active':{backgroundColor:`${purple.main} !important`,color:'white'},
    
    }}
      > 
      <HomeIcon 

fontSize={'small'} 
      style={{marginRight:'8px',}}/> Home </SldButtons.NavbarButton></li></ul>
    <FormControl 
    sx={{ m: 1, minWidth: 120,marginBottom:'20px!important' }} 
    size="small">
    <InputLabel id="demo-select-small-label">Dashboard Menu</InputLabel>
    <Select 
      labelId="demo-select-small-label"
      id="demo-select-small"
      value={dbmenu}
      label="Dashboard Menu"
      onChange={handleChange}
      sx={{borderRadius:'10px','&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ddd',
        boxShadow: `${purple.main} 0 0 0 0px`,
      },
    '& .MuiSelect-select':{padding:'7px'}}}
    >
      
      <MenuItem value={10}>Lead Self</MenuItem>
      <MenuItem value={20}>Team Dashboard</MenuItem>
      <MenuItem value={30}>MyTeamPulse</MenuItem>
    </Select>
  </FormControl></>
  );
};

export default DashboardMenu
