/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-06-06 23:59:18
 */
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import {Stack} from "@mui/material";
import { useState } from "react";
const SIDE_NAV_WIDTH = 250;

const VerticalLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const VerticalLayout = (props) => {
  // const typography = createTypography();
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [topTitle, setTopTitle] = useState();
  const mobileNav = useMobileNav();
  const updateTitle = (title) => {
    console.log("title in index", title);
    setTopTitle(title);
  };
  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} 
      toptitle={topTitle} />
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
          updatetitle={updateTitle}
        />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
     
      {/* <Stack direction="row" 
      justifyContent="space-between" >
        <Typography pl={35} 
        sx={typography.loremipsum}>
          Lorem ipsum
        </Typography>
        <Stack pr={4} 
        pt={2}>
          <SldButtons.BlackButtonCap
          variant="contained"
          size="medium"


        >
          <Typography sx={typography.completeSurveyButton}>
            Complete Work-With Survey
          </Typography>
        </SldButtons.BlackButtonCap>
        </Stack>
      </Stack> */}
      <VerticalLayoutRoot id="layoutContainer">
        
        <VerticalLayoutContainer><Stack mt={2}>{children}</Stack></VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
