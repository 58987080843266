/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:49
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-23 12:53:35
 */
export const useUser = () => {

  var item_value = JSON.parse(sessionStorage?.getItem("userDetails"));

  var retData = {
    id: item_value?.userId,
    avatar: '/assets/avatars/avatar-anika-visser.png',
    name: item_value?.userFirstName,
    email: item_value?.userEmail,
    firstName: item_value?.userFirstName,
    lastName: item_value?.userLastName,
    authType: item_value?.authType,
    hasIsl: item_value?.has_isl,
    tenetId: item_value?.tenant_id,
    type: item_value?.type,
    changePassword: item_value?.changePassword,
    roleId: item_value?.roleId,
  }

  return retData
};
