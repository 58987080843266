/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-06-16 20:49:39
 */
import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import {Box,Divider} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { AccountButton } from "../account-button";
import { SearchButton } from "../search-button";
import { createTypography } from "src/theme/base/customTypography";
import { paths } from "src/paths";
import { useState } from "react";
// import { saveAs } from "../../../index";
import * as SldButtons from "src/theme/base/SldButtons";
import { ReactComponent as DownloadPageIcon } from "src/icons/untitled-ui/downloadPage.svg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate, useLocation } from "react-router-dom";
import { DownloadPagePopUp } from "../popUps/downloadPagePopUp";
const TOP_NAV_HEIGHT = 85;
const SIDE_NAV_WIDTH = 250;

export const TopNav = (props) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current location

  const navigateToExplore = () => {
    navigate("/explorerPage");
  };
  const typography = createTypography();
  const { onMobileNavOpen, toptitle, ...other } = props;
  const [isLoading
    // , setIsLoading
  ] = useState(false);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  // const getShareLink = async (type) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await saveAs(0, type, toptitle);
  //     setIsLoading(response);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const openPopup = () => {
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  const getTitle = (path) => {
    switch (path) {
      case paths.dashboard.leadself.index:
        return "My Personality Profile";
      case paths.dashboard.mymojo.index:
        return "My Mojo";
      case paths.dashboard.myLeadership.index:
        return "My Leadership";
      case paths.dashboard.myExecutiveHealth.index:
        return "My Executive Health";
      case paths.dashboard.myPulseTrends.index:
        return "My Pulse Trends";
      case paths.dashboard.islProgrammes.goalsAndPulses:
        return "My Goals & Pulses";
      case paths.dashboard.conversationWithBoss.index:
        return "Conversation With Boss";
        case paths.dashboard.coachesCorner.index:
          return "Coaches Corner";
      default:
        return "Dashboard";
    }
  };

  const title = getTitle(location.pathname); // Get title based on path

  return (
    <Box
      component="header"
       id="topNavWidth"
      sx={{
        backdropFilter: "blur(6px)",
        backgroundColor: '#f9f9f9',
        position: "sticky",
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="flex-end"
   
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 6,
          marginBottom: 1.5
        }}
      >
        <Stack alignItems="center"
          direction="row"
          spacing={1}
          sx={{ flexGrow: '2', }}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          <Typography 
            sx={typography.profileHeaing}>
              {/* {toptitle} */}
              {title}

              </Typography>
          <SearchButton />
        </Stack>
        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"

          spacing={0}>
          <SldButtons.WhiteButtonSIcon sx={{ flexWrap: 'wrap', display: 'flex' }}
            id="downloadButton"
            variant="outlined"
            loading={isLoading}
            onClick={openPopup}
            // onClick={() => getShareLink("pdf")}
            startIcon={<DownloadPageIcon />}
          >
            <span>{isMobile ? null : 'Download Page'}</span>
          </SldButtons.WhiteButtonSIcon>
        </Stack>
        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"

          spacing={0}>          <IconButton>
            <HelpOutlineIcon />
          </IconButton>
          <IconButton id="downloadButton2"
           onClick={navigateToExplore}>
            <LanguageIcon />
          </IconButton>
          <AccountButton />
        </Stack>
      </Stack>
      <Divider sx={{mx:6}}/>
      <DownloadPagePopUp open={isPopupOpen}
        onClose={closePopup}
        surveyId={6}
        fileName={title} />
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
