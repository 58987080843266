import { Grid } from "@mui/material";
import React from "react";

function AddGoalLayout(props) {
  const { children } = props;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      p={3}
      spacing={3}
      sx={{ backgroundColor: "#535B661A" }}
    >
      <Grid item
lg={10}
md={10}
sm={10}
xs={10}
>
        {children}
      </Grid>
    </Grid>
  );
}

export default AddGoalLayout;
