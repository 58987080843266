import React, { createContext, useContext, useState } from "react";

const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
  const [selectedTeamId, setSelectedTeamId] = useState([]);
  const [applyTeams, setApplyTeams] = useState(false);
  const [teamInfo, setTeamInfo] = useState([]);
  const [orgLevel, setOrgLevel] = useState(false);
  const [orgName, setOrgName] = useState(null);

  const setTeamId = (teamId, teamName, divisionName) => {
    setSelectedTeamId((prevIds) => [...prevIds, teamId]);
    setTeamInfo((prevTeamInfo) => [
      ...prevTeamInfo,
      { teamId, teamName, divisionName },
    ]);
  };

  const removeTeamId = (teamId) => {
    setSelectedTeamId((prevIds) => prevIds.filter((id) => id !== teamId));
    setTeamInfo((prevTeamInfo) =>
      prevTeamInfo.filter((team) => team.teamId !== teamId)
    );
  };

  const removeAllTeamIds = () => {
    setSelectedTeamId([]);
  };

  const settingOrgLevelStateTrue = () => {
    setOrgLevel(true);
  };

  const settingOrgLevelStateFalse = () => {
    setOrgLevel(false);
  };

  const settingOrgName = (name) => {
    setOrgName(name);
  };

  const applySelectedTeams = () => {
    setApplyTeams(true);
  };

  const unApplySelectedTeams = () => {
    setApplyTeams(false);
  };

  return (
    <TeamContext.Provider
      value={{
        selectedTeamId,
        setTeamId,
        removeTeamId,
        applyTeams,
        teamInfo,
        setTeamInfo,
        applySelectedTeams,
        unApplySelectedTeams,
        removeAllTeamIds,
        settingOrgLevelStateTrue,
        settingOrgLevelStateFalse,
        orgLevel,
        settingOrgName,
        orgName
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeam = () => {
  return useContext(TeamContext);
};
