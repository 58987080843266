/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-06-07 00:06:20
 */
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import Typography from "@mui/material/Typography";
import { useMobileNav } from "./use-mobile-nav";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { createTypography } from "src/theme/base/customTypography";
import surveyAPIService from "src/services/surveyApi";
import { useNavigate } from "react-router";
import * as SldButtons from "src/theme/base/SldButtons";
import CryptoJS from 'crypto-js';
import { useUser } from "src/hooks/use-user";


const SIDE_NAV_WIDTH = 250;

const VerticalLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const VerticalLayout = (props) => {
  const typography = createTypography();
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [topTitle, setTopTitle] = useState();
  const mobileNav = useMobileNav();
  const accesssToken = sessionStorage.getItem("accessToken");
  const [showLockScreen, setShowLockScreen] = useState(true);
  const [wiredUpLocked, setWiredUpLocked] = useState(true);
  const [workWithLocked, setWorkWithLocked] = useState(true);
  const Navigate = useNavigate();
  const user =useUser();
  const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  const surveyPerms = CryptoJS.AES.decrypt(surveyPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8);
  let label = '';
  let shouldRenderButton = false;
console.log("Complete the surveys",showLockScreen,wiredUpLocked,workWithLocked);
  if (showLockScreen && wiredUpLocked && workWithLocked) {
    label = 'Complete the surveys';
    shouldRenderButton = false;
  } else if (surveyPerms && surveyPerms.includes(7) && !showLockScreen && wiredUpLocked && !workWithLocked) {
    label = 'Complete Wired-Up Survey';
    shouldRenderButton = true;
  } else if (surveyPerms && surveyPerms.includes(8) && !showLockScreen && !wiredUpLocked && workWithLocked) {
    label = 'Complete Work-With Survey';
    shouldRenderButton = true;
  }

  const handleClick = () => {
    console.log("lable", label);
    // Navigate based on the label
    switch (label) {
      case 'Complete the surveys':
        // Handle the default case or navigate to a common route
        break;
      case 'Complete Wired-Up Survey':
        Navigate('/wiredUpSurvey?surveyId=7');
        break;
      case 'Complete Work-With Survey':
        Navigate('/workWithSurvey?surveyId=8');
        break;
      default:
        // Handle other cases
    }
  };

  const updateTitle = (title) => {
    console.log("title in index", title);
    setTopTitle(title);
  };

  const surveyStatusCheck = async () => {
    try {
      const response = await surveyAPIService.checkSurveyStatus(accesssToken);
      console.log("status",response);
      if (response) {
        if (response.data.isLocked === false) {
          setShowLockScreen(false);
        }
        if (response.data.wiredupIsLocked === false) {
          setWiredUpLocked(false);
        }
        if (response.data.workwithIsLocked === false) {
          setWorkWithLocked(false);
        }
        // setIsLoading(false);
      }
    } catch (error) {}
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    surveyStatusCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentHref = window.location.pathname;
  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} 
      toptitle={topTitle} />
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
          updatetitle={updateTitle}
        />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <Stack direction="row" 
      sx={{
        justifyContent:{lg:'flex-end', md:'flex-end', sm:'center', xs:'center'}
      }} >
      
        <Stack pr={6} 
          >
        { shouldRenderButton && currentHref==='/leadSelf' ? (
            <SldButtons.BlackButtonCap
            variant="contained"
              size="medium"
              onClick={() => handleClick()}
            >
              <Typography sx={typography.completeSurveyButton}>{label}</Typography>
            </SldButtons.BlackButtonCap>
          ) : null}
         {/* {topTitle==='My Personality Profile'&& <Button
          variant="contained"
          size="medium"
          style={{
            backgroundColor: isHovered ? "grey" : "black",
            color: isHovered ? "black" : "white",
            borderRadius: "50px",
            transition: "background-color 0.3s, color 0.3s",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Typography sx={typography.completeSurveyButton}>
            Complete Work-With Survey
          </Typography>
        </Button>} */}
        </Stack>
      </Stack>
      <VerticalLayoutRoot id="layoutContainer">
        <VerticalLayoutContainer><Stack mt={2}>{children}</Stack></VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
