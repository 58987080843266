/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-06-13 16:23:50
 */
import axios from "axios";
import { Url } from "src/config";

const mtpDashboardResultsApi = {
    getMTPdashboardResult : async (accessToken, teamId, duration) => {
        console.log("api",teamId);
        try {
            const response = await axios.get(
                `${Url}/customer/getMTPdashboardResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        duration
                    }
                }
            );

            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    fetchTeamNarrativeQuestions : async (accessToken, inviteId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getTeamNarrativeQuestions`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        inviteId: inviteId,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    fetchNarrativeQuestionAnswers: async (accessToken, inviteId, qusId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getNarrativeQuestionAnswers`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        inviteId: inviteId,
                        qusId: qusId
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getUserTeamTree: async (accessToken, id) => {
        try {
          const response = await axios.get(`${Url}/customer/getUserTeamTree`,{
            headers: {
              "access-token": accessToken,
            },
            params: {
              id: id
            },
          });
          console.log("api",response);
          return response.data;
        } catch (error) {
          return error
        }
      },
    getLeadershipPopupResult : async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getLeadershipPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getMojoPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getMojoPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getLowMojoMembers: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getlowmojomembers`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getCulturePopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getCulturePopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getRiskPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getRiskPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getProductivityPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getProductivityPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getPromotersPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getPromotersPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getEngagementPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            console.log("accessToken", accessToken);
            const response = await axios.get(
                `${Url}/customer/getEngagementPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId.join(',')
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getEffectivenessPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            console.log("teamIdkkkkkkkkkkkk", teamId);
            const response = await axios.get(
                `${Url}/customer/getEffectivenessPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getValuesPopup: async (accessToken, teamIds) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getValuesResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamIds
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getJsonData: async (accessToken, teamIds) => {
        try {
            const response = await axios.get(
                `${Url}/customer/share/mtp/getcsv`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamIds.join(',')
                    }
                }
            );
            return response.data.content;
        } catch (error) {
            return error
        }
    },
    getVotPopup: async (accessToken, teamIds, invID) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getVoiceOfTeamResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamIds,
                        inviteId: invID
                    }
                }
            );
            return response.data;
        } catch (error) {
            return error
        }
    },
    getTeamDynamicsResult: async (accessToken, teamId, dashboard) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getTeamDynamicsResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId,
                        dashboard: dashboard
                    }
                }
            );
            return response.data.data.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    get360mriSurveyResult: async (accessToken, teamId) => {
        try {
            console.log('--- inside 360 m ');
            const response = await axios.get(
                `${Url}/customer/get360mriSurveyResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId
                    }
                }
            );
            console.log('360 response dataa', response);
            return response.data
        } catch (error) {
            console.log(error.message);
        }
    },

    get360mriCategoryResult: async (accessToken, teamId, categoryId, inviteId) => {
        try {
            console.log('--- inside 360 m ');
            const response = await axios.get(
                `${Url}/customer/get360CategoryData?teamId=${teamId}&categoryId=${categoryId}&inviteId=${inviteId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            console.log('360 response dataa', response);
            return response.data
        } catch (error) {
            console.log(error.message);
        }
    },
    getAllUsersResult: async (accessToken, teamId, categoryId, inviteId) => {
        try {
            console.log('--- inside 360 member result --- ');
            const response = await axios.get(
                `${Url}/customer/allMembersScore?teamId=${teamId}&categoryId=${categoryId}&inviteId=${inviteId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            console.log('360 response dataa', response);
            return response.data
        } catch (error) {
            console.log(error.message);
        }
    },
    postInviteShareLInk: async (htmlContent, SurveyId,accessToken) => {
        try {
            const response = await axios.post(
                `${Url}/customer/share/dashboard?surveyId=${SurveyId}`, htmlContent,
                {
                    headers: {
                        "access-token": accessToken,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    get360DashboardResult : async (accessToken, teamId) => {
        try {
            console.log('--- inside 360 dashboard  result --- ');
            const response = await axios.get(
                `${Url}/customer/360Result?teamId=${teamId}`,
               { 
                    headers: {
                        "access-token": accessToken,
                    },
                   
               }
            );
            console.log('360 response dataa',response);
            return response.data
        } catch (error) {
            console.log(error.message);
            return error;
        }
    },
    getTeamLeaders : async (accessToken, teamId) => {
        try {
            console.log('--- inside 360 dashboard  result --- ');
            const response = await axios.get(
                `${Url}/customer/mtpDashboard/teamleaders?team_id=${teamId}`,
               { 
                    headers: {
                        "access-token": accessToken,
                    },
                   
               }
            );
            console.log('360 response dataa',response);
            return response.data
        } catch (error) {
            console.log(error.message);
            return error;
        }
    },
    sendEmail: async (Content,accessToken) => {
        try {
            const response = await axios.post(
                `${Url}/customer/sendemail`, Content,
                {
                    headers: {
                        "access-token": accessToken,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    ISLUsersMTPDashboard : async (accessToken, userId) => {
        try {
            console.log('---inside get mtp resukts of isl api---');
            const response = await axios.get(
                `${Url}/customer/mtpDashboard/ISLUser?userId=${userId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                   
                }
            );
            console.log('---response in api',response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            return error
        }
    },

}



export default mtpDashboardResultsApi;