/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-06-16 20:49:14
 */
import ReactDOM from 'react-dom/client';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { App } from 'src/app';
import mtpDashboardResultsApi from './services/mtpDashboardApi';
import { Buffer } from 'buffer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const root = ReactDOM.createRoot(document.getElementById('root'));
export async function saveAs(surveyId, type, fileName, json) {
  try {
    if (type === "html") {
      const downloadButton = document.getElementById('downloadButton');
      downloadButton && (downloadButton.style.display = 'none')// Hide the button before generating PDF
      const downloadButton1 = document.getElementById('downloadButton1');
      downloadButton1 && (downloadButton1.style.display = 'none') // Hide the button before generating PDF
      const downloadButton2 = document.getElementById('downloadButton2');
      downloadButton2 && (downloadButton2.style.display = 'none') // Hide the button before generating PDF
      const downloadButton3 = document.getElementById('downloadButton3');
      downloadButton3 && (downloadButton3.style.display = 'none') // Hide the button before generating PDF
      const downloadButton4 = document.getElementById('downloadButton4');
      downloadButton4 && (downloadButton4.style.display = 'none') // Hide the button before generating PDF
      const downloadButton5 = document.getElementById('downloadButton5');
      downloadButton5 && (downloadButton5.style.display = 'none') // Hide the button before generating PDF
      const drawerElement = document.getElementById('drawerr');
      drawerElement.style.display = 'none';
      const layoutContainer = document.getElementById('layoutContainer');
      layoutContainer.style.paddingLeft = 0;
      const topNavWidth = document.getElementById('topNavWidth');
      topNavWidth.style.left = 0;
      const element = document.getElementById('root');
      let htmlContent = element.outerHTML;
      const accessToken = sessionStorage.getItem("accessToken");
      const stylesheets = document.styleSheets;
      for (let i = 0; i < stylesheets.length; i++) {
        const stylesheet = stylesheets[i];
        if (stylesheet.href) {
          fetch(stylesheet.href)
            .then(response => response.text())
            // eslint-disable-next-line
            .then(css => {
              htmlContent += `<style>${css}</style>`;
            });
        } else {
          const rules = stylesheet.cssRules;
          if (rules) {
            let css = '';
            for (let j = 0; j < rules.length; j++) {
              css += rules[j].cssText;
            }
            htmlContent += `<style>${css}</style>`;
          }
        }
      }
      const base64Encoded = Buffer.from(htmlContent).toString('base64');
      const staticJson = {
        "html": base64Encoded
      };
      const response = await mtpDashboardResultsApi.postInviteShareLInk(
        staticJson,
        surveyId,
        accessToken
      );
      drawerElement.style.display = 'block';
      layoutContainer.style.paddingLeft = "250px";
      topNavWidth.style.left = "250px";
      //  window.location.reload();
      downloadButton && (downloadButton.style.display = 'flex')
      downloadButton1 && (downloadButton1.style.display = 'flex')
      downloadButton2 && (downloadButton2.style.display = 'flex')
      downloadButton3 && (downloadButton3.style.display = 'flex')
      downloadButton4 && (downloadButton4.style.display = 'flex')
      downloadButton5 && (downloadButton5.style.display = 'flex')
      return response;

    } else if (type === "pdf") {
      // Assuming your button has an id of 'downloadButton'
      const downloadButton = document.getElementById('downloadButton');
      downloadButton && (downloadButton.style.display = 'none')// Hide the button before generating PDF
      const downloadButton1 = document.getElementById('downloadButton1');
      downloadButton1 && (downloadButton1.style.display = 'none') // Hide the button before generating PDF
      const downloadButton2 = document.getElementById('downloadButton2');
      downloadButton2 && (downloadButton2.style.display = 'none') // Hide the button before generating PDF
      const downloadButton3 = document.getElementById('downloadButton3');
      downloadButton3 && (downloadButton3.style.display = 'none') // Hide the button before generating PDF
      const downloadButton4 = document.getElementById('downloadButton4');
      downloadButton4 && (downloadButton4.style.display = 'none') // Hide the button before generating PDF
      const downloadButton5 = document.getElementById('downloadButton5');
      downloadButton5 && (downloadButton5.style.display = 'none') // Hide the button before generating PDF
      const layoutContainer = document.getElementById('layoutContainer');
      layoutContainer.style.paddingLeft = 0;
      const topNavWidth = document.getElementById('topNavWidth');
      topNavWidth.style.left = 0;
      const drawerElement = document.getElementById('drawerr');
      drawerElement.style.display = 'none';
      const rootElement = document.getElementById('root');
      html2canvas(rootElement, {
        scale: 2, // You may need to adjust the scale for better quality
        logging: true, // Enable logging to troubleshoot any issues
        scrollX: 0, // Set scrollX to 0 to capture entire content
        scrollY: 0, // Set scrollY to 0 to capture entire content
        width: document.documentElement.scrollWidth,
        height: document.documentElement.scrollHeight // Set height to capture entire content
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 1);
        const pdf = new jsPDF({
          orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height]
        });

        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
        pdf.save(fileName ? `${fileName}.pdf` : 'app.pdf');
        drawerElement.style.display = 'block';
        layoutContainer.style.paddingLeft = "250px";
        // window.location.reload();
        topNavWidth.style.left = "250px";
        downloadButton && (downloadButton.style.display = 'flex')
        downloadButton1 && (downloadButton1.style.display = 'flex')
        downloadButton2 && (downloadButton2.style.display = 'flex')
        downloadButton3 && (downloadButton3.style.display = 'flex')
        downloadButton4 && (downloadButton4.style.display = 'flex')
        downloadButton5 && (downloadButton5.style.display = 'flex')
      });

      // html2canvas(rootElement, {
      //   scrollX: 0,
      //   scrollY: 0,
      //   windowWidth: document.documentElement.scrollWidth,
      //   windowHeight: document.documentElement.scrollHeight
      // }).then(canvas => {
      //   const imgData = canvas.toDataURL('image/jpeg', 1); // Adjust image quality as needed
      //   const pdf = new jsPDF('l', 'px', [canvas.width, canvas.height]);
      //   const imgProps = pdf.getImageProperties(imgData);
      //   const pdfWidth = pdf.internal.pageSize.getWidth();
      //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      //   pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      //   pdf.save(fileName ? `${fileName}.pdf` : 'app.pdf');
      // });

      // old code with high quality
      // const rootElement = document.getElementById('root');
      // html2canvas(rootElement, { scrollX: 0, scrollY: 0, windowWidth: document.documentElement.scrollWidth, windowHeight: document.documentElement.scrollHeight }).then(canvas => {
      //   const imgData = canvas.toDataURL('image/png', 0.7);
      //   const pdf = new jsPDF('l', 'px', [canvas.width, canvas.height]);
      //   const imgProps = pdf.getImageProperties(imgData);
      //   const pdfWidth = pdf.internal.pageSize.getWidth();
      //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      //   pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      //   pdf.save(fileName ? `${fileName}.pdf` : 'app.pdf');
      // });

      return false;
    } else if (type === "csv") {
      const convertToCsv = () => {
        const replacer = (key, value) => (value === null ? '' : value);
        const header = Object.keys(json[0]);
        let csv = json.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(',')
        );
        csv.unshift(header.join(','));
        csv = csv.join('\r\n');
        return csv;
      };
      const csv = convertToCsv();
      const csvData = new Blob([csv], { type: 'text/csv' });
      const csvUrl = URL.createObjectURL(csvData);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = csvUrl;
      hiddenElement.target = '_blank';
      hiddenElement.download = fileName ? `${fileName}.csv` : 'app.csv';
      hiddenElement.click();

    }

  } catch (error) {
    console.log(error.message);
  }
  // const blob = new Blob([htmlContent], { type: 'text/html' });
  // const url = window.URL.createObjectURL(blob);
  // const a = document.createElement('a');
  // a.href = url;
  // a.download = 'MtpDashboard.html';
  // a.click();
  // window.URL.revokeObjectURL(url);
}

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
