/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-24 01:37:40
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import { paths } from "src/paths";
import { ReactComponent as TeamSelector } from "../../icons/untitled-ui/TeamSelector.svg";
// import { ReactComponent as OrganisationView } from "../../icons/untitled-ui/OrganisationView.svg";
import { ReactComponent as OrganisationNarratives } from "../../icons/untitled-ui/OrganisationNarratives.svg";
import { ReactComponent as OrganisationAnalytics } from "../../icons/untitled-ui/OrganisationAnalytics.svg";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
export const useSections = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      {
        // subheader: t(tokens.nav.menu),
        subheader: "Menu",
        items: [
          {
            title: "Team Selector",
            path: paths.dashboard.mtp.index,
            icon: (
              <SvgIcon fontSize="small">
                <TeamSelector />
              </SvgIcon>
            ),
          },
          // {
          //   // title: t(tokens.nav.personalityProfile),
          //   title: "Organisation View",
          //   path: paths.dashboard.mymojo.index,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <OrganisationView />
          //     </SvgIcon>
          //   ),
          // },
          // {
          //   // title: t(tokens.nav.personalityProfile),
          //   title: "Team Diagnostics",
          //   path: paths.dashboard.mymojo.index,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <TeamDiagnostics />
          //     </SvgIcon>
          //   ),
          // },
          {
            // title: t(tokens.nav.personalityProfile),
            title: "Organisation Analytics",
            path: paths.dashboard.hrAnalytics.index,
            icon: (
              <SvgIcon fontSize="small">
                <OrganisationAnalytics />
              </SvgIcon>
            ),
          },
          {
            // title: t(tokens.nav.personalityProfile),
            title: "Organisation Narratives",
            path: paths.dashboard.mtpOrgNarrative.index,
            icon: (
              <SvgIcon fontSize="small">
                <OrganisationNarratives />
              </SvgIcon>
            ),
          },
          {
            // title: t(tokens.nav.personalityProfile),
            title: "MTP Settings",
            path: paths.dashboard.mtpSettings.index,
            icon: (
              <SvgIcon fontSize="small">
                <SettingsApplicationsIcon />
              </SvgIcon>
            ),
          },
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
};
