/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-13 19:08:04
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import { ReactComponent as TeamPulse } from "../../icons/untitled-ui/teamPulse.svg";
import { useUser } from "src/hooks/use-user";
import CryptoJS from 'crypto-js';
export const useSections = () => {
  const { t } = useTranslation();
  const user=useUser();
  const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
  const dashboardPerms = CryptoJS.AES.decrypt(dashboardPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8);

  return useMemo(() => {
    const sections = [
      {
        subheader: t(tokens.nav.menu),
        items: [
          {
            title: t(tokens.nav.home),
            path: paths.dashboard.leadself.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: "Team",
            path: paths.dashboard.teamMojo.index,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            ),
          },
        ],
      },
    ];

    // Check if dashboardPerms includes the required value (7)
    if (dashboardPerms && dashboardPerms.includes(7)) {
      sections[0].items.push({
        title: t(tokens.nav.myMojo),
        path: paths.dashboard.mtp.index,
        icon: (
          <SvgIcon fontSize="small">
            <TeamPulse />
          </SvgIcon>
        ),
      });
    }

    return sections;
  }, [t, dashboardPerms]);
};
