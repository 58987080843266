// eslint-disable-next-line


export const createTypography = () => {
  const link = document.createElement("link");
  link.href =
    "https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600;700&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);

  return {
    fontFamily:
      '"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    qNheading: {
      fontFamily: "Work Sans",
      color: "white",
      fontWeight: 700,
      fontSize: { lg: "38px",  md: "36px",sm: "34px", xs: "33px" },
      lineHeight: { lg: "42px", md: "40px", sm: "38px", xs: "37px" },
      letterSpacing: "0em",
      textAlign: "center",
      wordWrap: "break-word",
    },
    sUserName: {
      color: "white",
    fontStyle:'italic',
      fontSize: { lg: "24px", md: "22px", sm: "20px", xs: "18px" },
      lineHeight: { lg: "28px", md: "26px", sm: "24px", xs: "22px" },
      fontWeight: 300,
      letterSpacing: "0em",
      textAlign: "center",
      wordWrap: "break-word",
      marginTop:0,
    },
    sStitle: {
      fontSize: { xl: "24px", lg: "22x", md: "20px", sm: "18px", xs: "16px" },
      lineHeight: {
        xl: "28px",
        lg: "24px",
        md: "22px",
        sm: "20px",
        xs: "18px",
      },
      paddingTop: "12px",
      fontWeight: 600,
      letterSpacing: "0em",
      textAlign: "left",
      marginLeft: "15px",
      cursor: "pointer",
    },

    SurveyQues: { 
      fontSize: { xl: "18px", lg: "17px", md: "16px", sm: "16px", xs: "16px" },
      lineHeight: {
        xl: "22px",
        lg: "20px",
        md: "19px",
        sm: "19px",
        xs: "19px",
      },
      paddingTop: "5px",
      fontWeight: 600,
      letterSpacing: "0em",
      textAlign: "left",
      marginLeft: "15px",
      cursor: "pointer",
    },

    sQLabel: {
      fontSize: { xl: "17px", lg: "16px", md: "15px", sm: "15px", xs: "14px" },
      lineHeight: {
        xl: "22px",
        lg: "20px",
        md: "19px",
        sm: "19px",
        xs: "19px",
      },
      paddingTop: "5px",
      fontWeight: 400,
      letterSpacing: "0em",
      textAlign: "left",
      marginLeft: "15px",
      cursor: "pointer",
    },
    sQDes: {
      fontSize: { xl: "20px", lg: "17px", md: "16px", sm: "15px", xs: "14px" },
      lineHeight: {
        xl: "28px",
        lg: "24px",
        md: "22px",
        sm: "20px",
        xs: "18px",
      },
      paddingTop: "12px",
      fontWeight: 400,
      letterSpacing: "0em",
      textAlign: "left",
      marginLeft: "15px",
      cursor: "pointer",
    },
    qNTitle: {
      fontFamily: "Work Sans",
      color: "white",
      fontWeight: 500,
      fontSize: "20px",
    },
    startButton: {
      fontFamily: "Work Sans",
      padding: "5px",
      fontWeight: 500,
      fontSize: "20px",
    },

    complete: {
      color: "white",
      fontSize: { lg: "45px", md: "35px", sm: "25px", xs: "25px" },
      fontFamily: "Work Sans",
      fontWeight: 700,
    },
    thankYou: {
      color: "white",
      fontSize: { lg: "32px", md: "27px", sm: "20px", xs: "17px" },
      fontFamily: "Work Sans",
      fontWeight: 400,
      letterSpacing: "0em",
      textAlign: "center",
      wordWrap: "break-word",
      lineHeight: "44px",
      padding: "0 15px",
    },
    feedBack: {
      color: "white",
      fontSize: { lg: "24px", md: "20px", sm: "17px", xs: "17px" },
      fontWeight: 500,
      lineHeight: { lg: "28px", md: "24px", sm: "20px", xs: "20px" },
      letterSpacing: "0em",
      textAlign: "center",
      padding: "15px",
    },
    cardContent: {
      fontSize: "18px",
      fontFamily: "Work Sans",
      fontWeight: 600,
    },
    disclaimer: {
      fontSize: "32px",
      color: "#583F99",
      fontFamily: "Work Sans",
      fontWeight: 600,
      paddingLeft:2
    },
    headingB: {
      fontSize: "20px",
      fontFamily: "Work Sans",
      fontWeight: 600,
      lineHeight: "36px",
    },
    content: {
      fontSize: "16px",
      fontFamily: "Work Sans",
      fontWeight: 400,
    },
    personality: {
      fontSize: 18,
      fontFamily: "Work Sans",
      fontWeight: 600,
      color: "#535B66",
    },
    personalityOptions: {
      fontSize: "16px",
      fontFamily: "Work Sans",
      fontWeight: 500,
    },
    tileHeading: {
      fontSize: "20px",
      color: "#535B66",
      fontFamily: "Work Sans",
      fontWeight: 500,
    },
    personalityOption2: {
      fontSize: 20,
      fontFamily: "Work Sans",
      fontWeight: 400,
    },
    characterCode: {
      fontSize: "16px",
      fontFamily: "Work Sans",
      fontWeight: 500,
    },
    tileHeadingLeadSelf: {
      fontSize: "20px",
      fontFamily: "Work Sans",
      fontWeight: 600,
      color: "#535B66",
    },
    QuestionareDue: {
      fontSize: "20px",
      fontFamily: "Work Sans",
      fontWeight: 600,
    },
    surveyMyMojo: {
      fontSize: "17px",
      fontFamily: "Work Sans",
      fontWeight: 600,
      color: "#583F99",
    },
    accordianContent: {
      fontSize: "18px",
      fontFamily: "Work Sans",
      fontWeight: 500,
    },
    userName: {
      fontSize: "19px",
      fontFamily: "Work Sans",
      fontWeight: 500,
      lineHeight:'22px',
      marginBottom:"30px!important",
      color:'black'
    },
    profileHeaing: {
      fontSize: { xl: "35px", lg: "30px", md: "20px", sm: "18px", xs: "18px" },
      fontFamily: "Work Sans",
      fontWeight: 700,
      color:'#212427',
    },
    loremipsum: {
      fontSize: "20px",
      fontFamily: "Work Sans",
      fontWeight: 500,
      color: "#535B66",
    },
    mojoBatteryValue: {
      fontSize:{ xl:"88px",lg:'68px',md:'70px', sm:'70px',xs:'70px',},
      fontFamily: "Work Sans",
      fontWeight: 400,
    },
    lockScreen: {
      fontSize: "20px",
      fontFamily: "Work Sans",
      fontWeight: 500,
      color: "#535B66",
    },
    completeSurveyButton: {
      fontSize: "16px",
      fontFamily: "Work Sans",
      fontWeight: 700,
    },
    numberScore: {
      color: "#FFFFFF",
      fontSize: 24,
      fontWeight: 500,
    },

    tcBlocks: {
      fontSize: { lg: "1rem", md: "1rem", sm: ".8rem", xs: ".8rem" },
      fontWeight:'600',
      textAlign: "center",
      color: "#fff",
      transform: {
        lg: "rotate(0deg)",
        md: "rotate(0deg)",
        sm: "rotate(0deg)",
        xs: "rotate(-90deg)",
      },
      cbRotate: {
        color: "#535B66",
        transform: {
          lg: "rotate(0deg)",
          md: "rotate(0deg)",
          sm: "rotate(0deg)",
          xs: "rotate(-45deg)",
        },
      },
      noRotate: {
        transform: {
          lg: "rotate(0deg)",
          md: "rotate(0deg)",
          sm: "rotate(0deg)",
          xs: "rotate(0deg)",
        },
        textAlign: "left",
        color: "#535B66",
      },
    },

    trendColorText: {
      regular: {
        fontSize:{lg: "18px", md: "17px", sm: "18px", xs: "18px" },
        lineHeight:{lg: "23px", md: "20px", sm: "20px", xs: "18px" },
        marginTop:{lg: "0px", md: "0px", sm: "18px!important", xs: "18px!important"},
        fontWeight: 600,
        
      },
      smallText: { color: "#color:'rgb(33, 36, 39)'", fontWeight: 400 , fontSize:{lg: "18px", md: "16px", sm: "18px", xs: "18px" },
      lineHeight:{lg: "23px", md: "18px", sm: "20px", xs: "18px" },},
    },

    //Optimizing the font size ans others (from here )
    subtitle: {
      regular: {
        fontSize: 16,
        fontWeight: 500,
      },
      regularSub: {
        fontSize: { lg: "16px", md: "15px", sm: "14px", xs: "14px" },
        fontWeight: 600,
      },
      regularbc: {
        fontSize: { lg: "20px", md: "18px", sm: "16px", xs: "16px" },
        fontWeight: 500,
        color:'rgba(83, 91, 102,.5)',
        '&:hover':{color:'rgb(88, 63, 153)'}
      },
      regularThin: {
        fontSize: 16,
        fontWeight: 400,
      },
      regularColorless: {
        fontSize: { lg: "15px", md: "14px", sm: "12px", xs: "12px" },
        fontWeight: 500,
      },
      regularLatest: {
        fontSize: { lg: "13px", md: "12px", sm: "12px", xs: "12px" },
        fontWeight: 500,
      },
      regularBoldColorless: {
        fontSize: 14,
        fontWeight: 600,
      },
      medium: {
        fontSize: 20,
        fontWeight: 500,
      },
      mediumColorless: {
        fontSize: 20,
        fontWeight: 500,
      },
      mediumColorlessdp: {
        fontSize: 18,
        fontWeight: 500,
      },
      mediumccsub: {
        fontSize: {
          xl: "16px",
          lg: "16px",
          md: "14px",
          sm: "14px",
          xs: "14px",
        },
        fontWeight: 500,
        
    
      },
      sort: {
        fontSize: {
          xl: "17px",
          lg: "16px",
          md: "15px",
          sm: "14px",
          xs: "14px",
        },
        lineHeight: {
          xl: "25px",
          lg: "20px",
          md: "22px",
          sm: "20px",
          xs: "20px",
        },
        fontWeight: 600,
      },

      sortORg: {
        fontSize: {
          xl: "15px",
          lg: "13px",
          md: "12px",
          sm: "12px",
          xs: "12px",
        },
        lineHeight: {
          xl: "25px",
          lg: "20px",
          md: "22px",
          sm: "20px",
          xs: "20px",
        },
        fontWeight: 600,
      },
      sortby: {
        fontSize: {
          xl: "15px",
          lg: "15px",
          md: "15px",
          sm: "13px",
          xs: "13px",
        },
        lineHeight: {
          xl: "20px",
          lg: "20px",
          md: "20px",
          sm: "15px",
          xs: "15px",
        },
        color:'rgba(83, 91, 102,.5)',
        fontWeight: 400,
      },
    },
    contents: {
      regular: {
        fontSize: 18,
        fontWeight: 500,
      },
      regular2: {
        fontSize: 16,
        fontWeight: 600,
      },
      regular3: {
        fontSize: 15,
        fontWeight: 400,
        lineHeight:'17px'
      },
      regular1: {
        fontSize: 14,
        fontWeight: 500,
      },
      small: {
        fontSize: 15,
        fontWeight: 400,
      },
      bc: {
        fontSize: {lg:'20px',md:'18px',sm:'16px',xs:'16px'},
        fontWeight: 600,
      },
      big: {
        fontSize: {lg:'24px',md:'20px',sm:'18px',xs:'16px'},
        fontWeight: 500,
      },
      gpTitle: {
        fontSize: {xl:'24px',lg:'16px',md:'17px',sm:'16px',xs:'15px'},
        fontWeight: 600,
        color:'#212427'
      },
      gpDes: {
        fontSize: {xl:'20px',lg:'14px',md:'14px',sm:'14px',xs:'13px'},
       
        color:'#212427'
      },
      bigBold: {
        fontSize: {lg:'24px',md:'20px',sm:'18px',xs:'16px'},
        fontWeight: 600,
      },
      huge: {
        fontSize: {xl:'32px',lg:'28px',md:'24px',sm:'20px',xs:'20px'},
        fontWeight: 400,
      },
      hugeMid: {
        fontSize: 32,
        fontWeight: 500,
      },
      hugeBold: {
        fontSize: 32,
        fontWeight: 600,
      },
      huge0: {
        fontSize: {xl:'40px',lg:'35px',md:'30px',sm:'28px',xs:'20px'},
        fontWeight: 700,
      },
      huge1: {
        fontSize: {xl:'46px',lg:'40px',md:'30px',sm:'28px',xs:'20px'},
        fontWeight: 300,marginTop:{xl:'20px',lg:'20px',md:'15px',sm:'15px',xs:'15px'}
      },
      huge2: {
        fontSize: 64,
        fontWeight: 500,
      },
    },
    cardHeader: {
      regular: {
        fontSize: {
          xl: "20px",
          lg: "18px",
          md: "16px",
          sm: "15px",
          xs: "15px",
        },
        lineHeight: {
          xl: "28px",
          lg: "24px",
          md: "22px",
          sm: "20px",
          xs: "20px",
        },
        fontWeight: 600,
      },

      regularccname: {
        fontSize: {
          xl: "18px",
          lg: "16px",
          md: "15px",
          sm: "14px",
          xs: "14px",
        },
        lineHeight: {
          xl: "28px",
          lg: "24px",
          md: "22px",
          sm: "20px",
          xs: "20px",
        },
        fontWeight: 600,
        color:'#212427'
      },

      regulart: {
        fontSize: {
          xl: "16px",
          lg: "16px",
          md: "15px",
          sm: "13px",
          xs: "13px",
        },
        lineHeight: {
          xl: "19px",
          lg: "19px",
          md: "18px",
          sm: "16px",
          xs: "16px",
        },
        fontWeight: 600,
      },
      regularmtp: {
        padding:'0 0 0 5px',
        color: "#535B66",
        fontSize: {
          xl: "20px",
          lg: "17px",
          md: "16px",
          sm: "15px",
          xs: "15px",
        },
        lineHeight: {
          xl: "28px",
          lg: "22px",
          md: "22px",
          sm: "20px",
          xs: "20px",
        },
        fontWeight: 600,
      },
      medium: {
        fontSize: {
          xl: "18px",
          lg: "15px",
          md: "16px",
          sm: "15px",
          xs: "15px",
        },
        lineHeight: {
          xl: "25px",
          lg: "20px",
          md: "22px",
          sm: "20px",
          xs: "20px",
        },
        fontWeight: 600,
      },

      
      bold: {
        fontSize: 20,
        fontWeight: 600,
      },
      thin: {
        fontSize: 20,
        fontWeight: 400,
      },
    },
    cardBrackets: {
      regular: {
        fontSize: 16,
        fontWeight: 400,
      },
    },
  };
};
