/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-06-18 12:15:33
 */
import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import Joyride from 'react-joyride';
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Stack, Divider } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { AccountButton } from "../account-button";
import { SearchButton } from "../search-button";
import { createTypography } from "src/theme/base/customTypography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// import { useState } from "react";
import { useDuration } from "src/contexts/mtp-duration-context";
import * as SldButtons from "src/theme/base/SldButtons";
//import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
//import { ReactComponent as SLDLogo } from "src/icons/untitled-ui/sldLogoLoading.svg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LanguageIcon from "@mui/icons-material/Language";
import { ReactComponent as DownloadPageIcon } from "src/icons/untitled-ui/downloadPage.svg";
// import { saveAsHtmlFile } from "../../../index";
import { DownloadPagePopUp } from "../../MTP Dashboard/popUps/downloadPagePopUp";
import { useState } from "react";
import { useTeam } from "src/contexts/mtp-team-context";
import { useNavigate } from "react-router";
import CustomTooltip from 'src/components/CustomTooltipJoyride.js';
import { useLocation } from 'react-router-dom';
import CustomBeacon from 'src/components/CustomBeaconJoyride.js';
import { useUser } from "src/hooks/use-user";
import HRAnalyticsApiService from "src/services/hrAnalyticsApi";


const TOP_NAV_HEIGHT = 85;
const SIDE_NAV_WIDTH = 250;

export const TopNav = (props) => {
  const [joyrideSteps, setJoyrideSteps] = useState([]);
  const [runJoyride, setRunJoyride] = useState(false);
  const location = useLocation();
  const typography = createTypography();
  const { onMobileNavOpen, toptitle, ...other } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { selectedDuration, setDuration } = useDuration();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { applyTeams, selectedTeamId } = useTeam();
  const [teamLeader, setTeamLeader] = useState(0);
  const accessToken = sessionStorage.getItem("accessToken");
  const openPopup = () => {
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
  };
  const navigate = useNavigate();
  const navigateToExplore = () => {
    navigate("/explorerPage");
  };
  const user = useUser();
  // const [alignment, setAlignment] = useState('monthly');
  const currentHref = window.location.pathname;
  const handleChange = (event, newAlignment) => {
    setDuration(newAlignment);
  };
  const isMobile = useMediaQuery('(max-width:600px)');
  console.log("togglebutton", selectedTeamId);
  useEffect(() => {
    const steps = {
      "/MTP": [
        {
          target: '.joyride-target-1',
          content: 'Walkthrough of SmartLeader Privacy Policy.',
        },
        {
          target: '.joyride-target-2',
          content: 'SmartLeader Privacy Policy Details',
        },
        {
          target: '.joyride-target-3',
          content: 'Information we Collect',
        },
        {
          target: '.joyride-target-4',
          content: 'How we Collect Information:'


        }
        // Add more steps as needed
      ],
    };
    fetchLeaderStatus();
    setJoyrideSteps(steps);
    // eslint-disable-next-line
  }, []);
  const fetchLeaderStatus = async () => {
    try {
      let data = await HRAnalyticsApiService.checkTeamLeader(accessToken);
      if (data) {
        setTeamLeader(data.team_leader);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <Box
      component="header"
      id="topNavWidth"
      sx={{
        backdropFilter: "blur(6px)",
        paddingRight: '0',
        backgroundColor: '#f9f9f9',
        position: "sticky",
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="flex-end"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 6,
          marginBottom: 1.5
        }}
      >
        <Stack alignItems="center"
          direction="row"
          spacing={1}
          sx={{ flexGrow: '2', }}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          <Typography
            sx={typography.profileHeaing}>
            {currentHref === "/mtporgnarrative" ? "Organisation Narratives" : "MyTeamPulse"}
          </Typography>
          {window.location.pathname !== "/hranalytics" && selectedTeamId.length > 1 && (
            <ToggleButtonGroup
              value={selectedDuration}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="monthly"
                style={{ color: "#00008B" }}>
                Monthly
              </ToggleButton>
              <ToggleButton value="quarterly"
                style={{ color: "#00008B" }}>
                Quarterly
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          <SearchButton />
        </Stack>
        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"

          spacing={0}>
          {((!selectedTeamId.length === 0 || applyTeams) || currentHref === "/hranalytics" || user.tenetId === 0) && (teamLeader === 1 || user.roleId?.toString() === "1000") && (
            //roleid 1000 is for MTP super admin
            <SldButtons.WhiteButtonSIcon sx={{ width: '100%' }}
              id="downloadButton"
              variant="outlined"
              onClick={openPopup}
              startIcon={<DownloadPageIcon />}
            >
              <span>{isMobile ? null : 'Download Page'}</span>
            </SldButtons.WhiteButtonSIcon>
          )}

          {/* <NotificationsButton /> */}
        </Stack>
        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"
          spacing={0}>
          <IconButton id="downloadButton1"
            onClick={() => setRunJoyride(true)}>
            <HelpOutlineIcon />
          </IconButton>

          <>

            <Joyride
              steps={joyrideSteps[location.pathname] || []}
              run={runJoyride}
              beaconComponent={CustomBeacon}
              continuous
              disableOverlayClose
              showProgress
              showSkipButton
              tooltipComponent={(props) => <CustomTooltip {...props}
                steps={joyrideSteps} />}
              styles={{
                options: {
                  arrowColor: '#583f99',
                  textColor: '#fff',
                  zIndex: 10000, backgroundColor: '#583f99'// Ensure Joyride is displayed above everything else
                },
                spotlight: {
                  borderRadius: '15px',
                },

              }}
            />
          </>
          <IconButton id="downloadButton2"
            onClick={navigateToExplore}>
            <LanguageIcon />
          </IconButton>
          <AccountButton />

        </Stack>

      </Stack><Divider sx={{ mx: 6 }} />
      <DownloadPagePopUp open={isPopupOpen}
        onClose={closePopup}
        surveyId={6}
        fileName={"MyTeamPulse"} />
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
