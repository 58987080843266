/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-06-16 20:50:12
 */
import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { AccountButton } from "../account-button";
import { SearchButton } from "../search-button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate } from "react-router";
import loginApiService from "src/services/loginVerifyAPI";
import { useUser } from "src/hooks/use-user";
const TOP_NAV_HEIGHT = 85;
const SIDE_NAV_WIDTH = 250;

export const TopNav = (props) => {
  const { onMobileNavOpen, ...other } = props;
  const user = useUser();
  const accessToken = sessionStorage.getItem("accessToken");
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const navigateToExplore = () => {
    navigate("/explorerPage");
  };
  const getPermissions = async () => {
    await loginApiService.getDashboardIds(accessToken, user.id);
    await loginApiService.getSurveyIds(accessToken, user.id);
  };
  const currentHref = window.location.pathname;
  // console.log("currentHref", currentHref);
  return (
    <Box
      component="header"
       id="topNavWidth"
      sx={{
        backdropFilter: "blur(6px)",
        paddingRight:'16px',
        backgroundColor: '#f9f9f9',
        position: "sticky",
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="flex-end"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
          marginBottom:1.5
        }}
      >

        <Stack alignItems="center"
          direction="row"
          spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          {// eslint-disable-next-line
            currentHref && currentHref != "/profile" &&
            <Typography pl={{ lg: '20px', md: '20px', sm: "10px", xs: '10px' }}
              ml={{ lg: '0px', md: '0px', sm: "0px", xs: '0px' }}
              onClick={getPermissions}
              variant="h4">Home</Typography>
          }
          <SearchButton />
        </Stack>

        <Stack alignItems="center"
          direction="row"
          spacing={2}>
          <IconButton>
            <HelpOutlineIcon />
          </IconButton>
          <IconButton id="downloadButton2"
           onClick={navigateToExplore}>
            <LanguageIcon />
          </IconButton>
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
