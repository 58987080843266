
import {  styled } from '@mui/system';
import { Card,Grid,Stack,Box,Accordion} from '@mui/material';




/* Custom card*/
const DiclaimerCard = styled(Card)(({ theme }) => {
  

  return {
   
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.08)",width:'100%',
   
    '& .MuiCardHeader-root':{padding:'36px 36px 0 36px'},
    '& .MuiCardContent-root':{padding:'36px'}

  };
});


const SurveyCardStyle = styled(Card)(({ theme }) => {
  

    return {
     
      height: 270,
      boxShadow: "0px 6px 12px 0px #00000030",
      border: "1px solid white",
      borderRadius: "24px",
      background: "linear-gradient(104deg, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0) 100%)",
    };
  });

  

  const DistriPref = styled(Card)(({ theme }) => {
    return {
     backgroundColor:'rgba(83, 91, 102,.03)',
     border:'1px solid rgba(83, 91, 102,.1)',
      paddingTop: '0',
    paddingBottom: '0',
    height: '90px',
    alignItems: 'center',
    justifyContent:'center',
    borderRadius:'10px',
    display: 'flex' };
  });

  /*Custom grid item */

  const SurveyGridItem = styled(Grid)(({ theme }) => {
    
    return {
 
      width: '100vw',
      backgroundSize: 'cover',
      borderBottomLeftRadius: '100px',
      borderBottomRightRadius: '100px',
      marginBottom: -2,
      [theme.breakpoints.up('xs')]: {
        height:'auto',
        backgroundColor: '#5d42ad', // Override background color for small screens
      },
    
      [theme.breakpoints.up('sm')]: {
        height:'auto',
        backgroundColor: '#5d42ad', // Override background color for medium screens
      },
    
      [theme.breakpoints.up('md')]: {
        height:'400px',
        backgroundImage: 'url(./assets/backgroundsSvg/questionaryBg.svg)', // Override background image for medium screens
      },
    
      [theme.breakpoints.up('lg')]: {
         height:'400px',
        backgroundImage: 'url(./assets/backgroundsSvg/questionaryBg.svg)', // Override background image for large screens
      },
    };
  });
  
  const SurveyStack = styled(Stack)(({ theme}) => {
    return {
      backgroundColor :"rgba(249, 249, 249, 0)",
      justifyContent: "space-evenly",
      alignItems: "center",
      margin: "auto",
     flexDirection:'column',

     width:'100%'
     
    };
  });
  const ClickwithCard = styled(Card)(({ theme}) => {
    return {
      
      minHeight: 550,
    };
  });

  const CWuCard = styled(Card)(({ theme}) => {
    return {
      
      width: "100%", marginTop: '2px', backgroundColor: "#F9F9F9"    
    };
  });

  const MyppCard = styled(Card)(({ theme}) => {
    return {
      
      boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.14)",
        borderRadius: "15px",};
  });

  const CcAccoSec= styled(Accordion)(({ theme}) => {
    return {
      '&.MuiAccordion-root.MuiAccordion-rounded':{boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.14)",
          borderBottom:'1px solid rgba(83,91,102,.4)',marginTop:0,
          borderRadius: '0px',backgroundColor:'transparent'},
          '& .MuiAccordionSummary-root':{backgroundColor: "#fff",borderRadius: '0px',},
          '& .MuiAccordionSummary-root:hover':{backgroundColor:'rgba(88, 63, 153,.5)'},
          '& .Mui-expanded':{backgroundColor: "#fff",borderRadius: '0px 0px 0 0',minHeight:'auto'},
          '& .MuiAccordionSummary-content':{margin: "8px 0!important", background:'none'},
          '& .MuiTypography-root':{fontSize:{lg:'14px', md:'14px',sm:'13px',xs:'13px'},fontWeight:'400'}};
  });
  const DottedStack = styled(Stack)(({ theme}) => {
    return {
      position: 'absolute',
      top: '250px',
      textAlign:'center',

      '@media (max-width: 900px)': {
        
        '::before': {
          content: '"Extroverts"', // Add content to the pseudo-element
          display: 'block',
          marginTop:'-15px',
          fontWeight:'600'
        },
      },
     
    };
  });

  const DottedStackb = styled(Stack)(({ theme}) => {
    return {
      position:'absolute',top:'400px',
      textAlign:'center',
      '@media (max-width: 900px)': {
        
        '::before': {
          content: '"Introverts"', // Add content to the pseudo-element
          display: 'block',
          marginTop:'-15px',
          fontWeight:'600'
        },
      },
     
    };
  });

  const LowestScoreR = styled(Grid)(({ theme}) => {
    return {
      '@media (max-width: 900px)': {
        
        '::before': {
          content: '"Lowest Score"', // Add content to the pseudo-element
          display: 'block',
          fontWeight:'300',
          textAlign:'center',
          color:'#555'
        },}
    };
  });

  const LowestScoreRisk = styled(Grid)(({ theme}) => {
    return {
      '@media (max-width: 900px)': {
        
        '::before': {
          content: '"Risk Pilots"', // Add content to the pseudo-element
          display: 'block',
          fontWeight:'300',
          textAlign:'center',
          color:'#555'
        },}
    };
  });

  const TeamScoreR = styled(Grid)(({ theme}) => {
    return {
      '@media (max-width: 900px)': {
        
        '::before': {
          content: '"Team Average"', // Add content to the pseudo-element
          display: 'block',
          fontWeight:'300',
          textAlign:'center',
          color:'#555',
          fontSize:{sm:'11px', xs:'11px'}
        },}
    };
  });

  const CwuStack = styled(Stack)(({ theme}) => {
    return {
      alignItems:"center",
    height:'150px',
  textAlign:"center",  
    };
  });

  const GoalsIcon = styled(Stack)(({ theme}) => {
    return {
      background:'rgba(88, 63, 153,.2)', borderRadius:'50%', padding:'5px', 
      width: '63px',
      height: '63px',
      textAlign: 'center',
      alignItems: 'center',
      justifyItems: 'center',
      justifyContent: 'center'  
    };
  });
  const CwaStack = styled(Stack)(({ theme}) => {
    return {
      alignItems:"center",
      justifyContent:"center",
      borderRadius: '100px',
      border: "1px solid black",
      
      minWidth: '80px',
      textAlign: "center",
      padding:'5px 8px',
      '& .MuiTypography-root':{fontSize:'.8rem',},
                "&:hover": {
                  backgroundColor: "black",
                  color:'white',}
    };
  });
  const MtpAvatarStack = styled(Stack)(({ theme}) => {
    return {
      backgroundColor: "#F9F9F9",
      borderRadius: '10px',
      border: "1px #E6E6E6 solid",
      alignItems:"center",
      justifyContent:"center",
      padding:'10px ',
      margin:'5px!important ',
      height:'100px',
      '& .MuiAvatar-root':{
        width: '30px',
        height: '30px',

      }
      

    };
  });

  const MtpAvatarCover= styled(Stack)(({ theme}) => {
    return {
          justifyContent:"space-evenly",
          alignItems:"center",
          margin:'0 auto'
      

    };
  });

  const MtpPopLow = styled(Stack)(({ theme}) => {
    return {
      borderRadius: '10px',
      padding:'5px',
      height:'50px',
      justifyContent:"center",
      alignItems:"center",
      textAlign:'center'
          }
  });
  const SurveyLogo = styled(Stack)(({ theme}) => {
    return {
      flexDirection:'row',
          justifyContent:"flex-start",
          alignItems:"flex-start",
          paddingTop:20,
        paddingLeft:70,
          }
  });






  const SmartLeaderBox = styled(Box)(({ theme}) => {
    return {
      display: "flex",
                height: "100%",
                padding: "4px",
                width: "100%",
                cursor:'pointer'
    };
  });
  const SurveyCardMedia = styled(Card)(({ theme}) => {
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: '18px',
      height: '140px',
      backgroundColor:'transparent',
      border:'none',
      borderRadius: "0",
    };
  });

  const SurveyCardContent = styled(Card)(({ theme}) => {
    return {
      display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            backgroundColor: "white",
            height: '130px',
    border: 'none',
    borderRadius: '0',
    padding: '31px',
    };
  });
  const MtpTeamCat = styled(Card)(({ theme}) => {
    return {
      width: 120,
      borderRadius: '10px',
      textAlign:'center',
      alignItems:'center',
      margin:'0 auto'
    };
  });

  const SurveyGrid = styled(Grid)(({ theme }) => {

    return {
      
      backgroundColor: {
        xs: '#5d42ad',
        sm: '#5d42ad',
        lg: '#F9F9F9',
      },

      [theme.breakpoints.up('xs')]: {
        backgroundColor: '#5d42ad', // Override background color for small screens
      },
      [theme.breakpoints.up('sm')]: {
        backgroundColor: '#5d42ad', // Override background color for medium screens
      },
      [theme.breakpoints.up('lg')]: {
        backgroundColor: '#F9F9F9', // Override background color for large screens
      },
    };
  });


  const CatCardTile = styled(Card)(({ theme}) => {
    return {
      borderStyle: "dashed",
      backgroundColor: "#F9F9F9",
      borderRadius: '15px',
      textAlign:'center'
    };
  });

  const SurveyRules = styled(Grid)(({ theme}) => {
    return { backgroundColor: "#F9F9F9", textAlign:'center', paddingBottom:'15px' ,width:'100%'   };
});
  const SurveyContainer = styled("div")({
    marginTop: 10,
    padding: 32,
    justifyContent: "center",
    alignItems: "center",
    textAlign:'left',
    display: "flex",
    // backgroundColor: "",
    width:'100%'
  });
 
export {
  SurveyLogo,
  GoalsIcon,
  SurveyRules,
  SurveyCardStyle,
  SurveyGrid,
  SurveyGridItem,
  SurveyStack,
  SurveyCardMedia,
  SurveyCardContent,
  DottedStack,
  DottedStackb,
  ClickwithCard,
  CWuCard,
  CwuStack,
  SmartLeaderBox,
CwaStack ,
MtpAvatarStack,
MtpPopLow,
LowestScoreR,
TeamScoreR,
MtpTeamCat,
MtpAvatarCover,
LowestScoreRisk,
CatCardTile,
DiclaimerCard,
SurveyContainer,
DistriPref,
MyppCard,
CcAccoSec
  
};