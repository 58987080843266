import axios from "axios";
import { Url } from "src/config";

const myLeadershipApi = {
  getMyLeadershipDashboard: async (accessToken, date) => {
    try {
      const params = {};

      if (date) {
        params.date = date;
      }

      const response = await axios.get(
        `${Url}/customer/dashboard/myLeadership`,
        {
          headers: {
            "access-token": accessToken,
          },
          params: params,
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  },
  getMyLeadershipDashboardUsers: async (accessToken, date, tenantId,userId) => {
    try {
      const params = {};

      if (date) {
        params.date = date;
      }

      const response = await axios.get(
        `${Url}/customer/dashboard/myLeadershipforCoach?tenant_id=${tenantId}&id=${userId}`,
        {
          headers: {
            "access-token": accessToken,
          },
          params: params,
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  },
  getMyLeadershStatus: async (accessToken) => {
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/getMyleadershipStatus`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      return response.data;
    } catch (error) {}
  },
  getTips: async (accessToken, inviteId, questionIds) => {
    try {
      const response = await axios.post(
        `${Url}/customer/dashboard/getleadershipTipsQuestions?inviteId=${inviteId}`,
        questionIds,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  },
  getMyLeadershipCategoryData: async (
    accessToken,
    inviteId,
    categoryId,
    invitedUser
  ) => {
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/getmyLeadershipCategory?inviteId=${inviteId}&categoryId=${categoryId}&invitedUser=${invitedUser}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      return response.data;
    } catch (error) {}
  },
  getLBI_SUI_Data: async (accessToken, inviteId, questionId) => {
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/lbisuidata?questionId=${questionId}&invitedId=${inviteId}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      return response.data;
    } catch (error) {}
  },
};

export default myLeadershipApi;
