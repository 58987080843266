import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { createTypography } from "src/theme/base/customTypography";
import { Scrollbar } from "src/components/scrollbar";
import { toast } from "react-hot-toast";

// import { ReactComponent as PlusBUtton } from "src/icons/untitled-ui/+ButtonWhite.svg";
import {
  Checkbox,
  Typography,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  // AccordionDetails,
} from "@mui/material";
import { useUser } from "src/hooks/use-user";
import { useEffect } from "react";
import teamDashboardResultsApi from "src/services/teamdashboardApi";
import { useState } from "react";
import { useTeam } from "src/contexts/team-context";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

export default function TemporaryDrawer() {
  const typography = createTypography();
  const user = useUser();
  const accessToken = sessionStorage.getItem("accessToken");
  const [isLoading, setIsLoading] = useState(true);
  const { selectedTeamId, setTeamId, setTeamName, clickWithPage, setToClickWithOrg, clickWithOrg } = useTeam();
  const [selectedDrawerTeamId, setSelectedTeamId] = useState(null);
  const [teamsList, setTeamsList] = useState();
  console.log("teamsList", teamsList);

  const fetchUsersTeams = async () => {
    try {
      const response = await teamDashboardResultsApi.getUsersTeams(
        accessToken,
        user.id
      );
      console.log("response messazge", response);
      if(response.message){
        toast.error(response.message)
        setIsLoading(false);
      }else{
        console.log("response", response.teamDetails.userDetails);
        setTeamsList(response.teamDetails.userDetails);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchUsersTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const storedTeamId = sessionStorage.getItem("teamDashboardTeamId");
    const storedTeamName = sessionStorage.getItem("teamDashboardTeamName");
    if(teamsList){
      if (storedTeamId && storedTeamName) {
        console.log("Type of stored team id :", typeof storedTeamId);
        const teamIdAsNumber = Number(storedTeamId);
        setTeamId(teamIdAsNumber);
        setTeamName(storedTeamName);
      } else {
        // If sessionStorage doesn't have teamDashboardTeamId, set the teamId from the API response
        console.log("type of team id :",typeof teamsList[0].teamId);
        setTeamId(teamsList[0].teamId);
        setTeamName(teamsList[0].teamName);
      }
    }
    
    // if (teamsList && teamsList.length > 0) {
    //   setTeamName(teamsList[0].teamName);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsList]);

  useEffect(() => {
    if (selectedDrawerTeamId) {
      setTeamId(selectedDrawerTeamId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDrawerTeamId]);

  useEffect(() => {
    if (selectedTeamId) {
      console.log("selectedTeamId", selectedTeamId);
    }
  }, [selectedTeamId]);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleChange1 = (event, teamId, teamName) => {
    setToClickWithOrg(false);
    setSelectedTeamId(teamId); // Assuming you have a state named selectedTeamId
    setState("left", false);
    setTeamName(teamName);
    // Set teamId into sessionStorage
    sessionStorage.setItem("teamDashboardTeamId", teamId);
    sessionStorage.setItem("teamDashboardTeamName", teamName);
  };

  const handleClickWithOrganisation = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setToClickWithOrg(true); // If isChecked is true, set it to true
    } else {
      setToClickWithOrg(false); // If isChecked is false, set it to false
    }
  };

  console.log("team name Check", teamsList);

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        height: "100%",
        backgroundColor: "#583F99",
        borderRadius: "0 15px 15px 0",
        zIndex: 1201,
        paddingLeft: "45px",
        paddingTop: "25px",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      p={2}
    >
      <Typography
        sx={{ ...typography.subtitle.regularColorless, color: "white" }}
      >
        Team Selector
      </Typography>
      <Scrollbar style={{ maxHeight: "100%", width: "100%" }}>
        {clickWithOrg && clickWithPage && (
          <List>
            <div>
              <Accordion sx={{ backgroundColor: "transparent" }}>
                <AccordionSummary>
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          ...typography.subtitle.regularColorless,
                          color: "white",
                        }}
                      >
                        Click With Organisation
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={clickWithOrg}
                        onChange={(event) => handleClickWithOrganisation(event)}
                      />
                    }
                  />
                </AccordionSummary>
              </Accordion>
            </div>
          </List>
        )}
        {!clickWithOrg && selectedTeamId && (
        <List>
          {teamsList.map((team, index) => (
            <div key={index}>
              <Accordion sx={{ backgroundColor: "transparent" }}>
                <AccordionSummary
                  // expandIcon={<PlusBUtton />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  onClick={(event) => event.stopPropagation()}
                >
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          ...typography.subtitle.regularColorless,
                          color: "white",
                        }}
                      >
                        {team.teamName}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={selectedTeamId === team.teamId}
                        onChange={(event) =>
                          handleChange1(event, team.teamId, team.teamName)
                        }
                      />
                    }
                  />
                </AccordionSummary>
                {/* <AccordionDetails>{children}</AccordionDetails> */}
              </Accordion>
            </div>
          ))}
        </List>
        )}
      </Scrollbar>
    </Box>
  );

  React.useEffect(() => {
    // Open the drawer directly when the component mounts
    setState((prev) => ({ ...prev, left: true }));
  }, []);

  useEffect(() => {
    if (teamsList) {
      setIsLoading(false);
    }
  }, [teamsList]);

  //loader animation will run until response fetched from backend
  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={state["left"]}
      onClose={toggleDrawer("left", false)}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0)!important", // Set the background color of the backdrop to transparent
        },
        "& .MuiPaper-root.MuiPaper-elevation": {
          left: "210px",
          zIndex: -1,
          overflow: "hidden",
        },
        "& .MuiPaper-root.MuiPaper-elevation .MuiPaper-root.MuiPaper-elevation":
          { left: 0, zIndex: 1 },
        "& .MuiButtonBase-root": {
          "& rect": { fill: "#fff", stroke: "#fff;" },
        },
        "& .MuiAccordionDetails-root .MuiBox-root": { marginLeft: 0 },
        "& .MuiAccordionSummary-root": {
          borderBottom: "1px solid rgba(255,255,255,.3)",
          borderTop: "1px solid rgba(255,255,255,.3)",
        },
        "& .MuiAccordionSummary-content": { margin: "2px 0!important" },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "25px",
          margin: "2px 0",
        },
        "& .MuiButtonBase-root.MuiCheckbox-root": { padding: "4px" },
        "& .MuiFormControlLabel-root .MuiTypography-root": { paddingLeft: 0 },
      }}
    >
      {list("left")}
    </Drawer>
  );
}
