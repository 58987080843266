import React from 'react';
import { Box,Typography, LinearProgress,Stack, IconButton } from '@mui/material'; // Import MUI components
import * as SldButtons from "src/theme/base/SldButtons";
import CloseIcon from '@mui/icons-material/Close';
const CustomTooltip = ({ continuous, index, step, backProps, closeProps, primaryProps, steps }) => {
  const currentStep = index + 1;
  const totalSteps = steps.length;

  return (
    <Box 
    
    bgcolor="#583f99" 
    borderRadius={1} 
    boxShadow={2} 
    width={400}
    sx={{'& .MuiLinearProgress-root':{backgroundColor:'rgba(255,255,255,.3)'},
'& .MuiLinearProgress-bar':{backgroundColor:'rgba(255,255,255,1)'}}}
    
    >
<Box  
px={2} 
display="flex" 
justifyContent="space-between" 
alignItems="center">
        

        <Box 
        textAlign="left" 
        sx={{ width: '100%', color: '#fff' }}>
          <Typography 
          sx={{ fontSize: '12px' }}>{currentStep}/{totalSteps} Steps</Typography>
        </Box>
        {/* Close button */}
        <IconButton {...closeProps} 
        sx={{ color: '#fff' }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box 
      sx={{padding:'5px 0'}}>
      <LinearProgress 
      variant="determinate" 
      value={(currentStep / totalSteps) * 100} />
</Box>

<Box  px={2}>
          <Typography 
          variant="h6" 
          sx={{color:'#fff'}}>{step.title}</Typography>
          <Typography 
          sx={{color:'#fff'}}>{step.content}</Typography>
        </Box >
      <Stack 
       p={2} 
       sx={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}
      mt={2} 
      ><Box 
      style={{minWidth:'100px'}}>
        {index > 0 && <SldButtons.WhiteButton {...backProps} 
        sx={{backgroundColor:'white', padding:'3px', color:'#583f99'

        }}>Back </SldButtons.WhiteButton>}</Box>
        <Box>
          <SldButtons.WhiteButton 
          sx={{padding:'3px',}} 
          {...primaryProps}>{index === steps.length - 1 ? 'Finish' : 'Next'}</SldButtons.WhiteButton>
          {!continuous && <SldButtons.WhiteButton {...closeProps}>Close </SldButtons.WhiteButton>}</Box>
        
      </Stack>
    </Box>
  );
};

export default CustomTooltip;
