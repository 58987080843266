import axios from "axios";
import { Url } from "src/config";

const mediaUploadApis = {
  uploadMedia: async (accessToken, dashboard, teamId, media) => {
    try {
      const formData = new FormData();
      formData.append("media", media);
      const response = await axios.post(
        `${Url}/customer/uploadMedia`,
        formData,
        {
          headers: {
            "access-token": accessToken,
          },
          params: {
            teamId: teamId,
            dashboard: dashboard,
          },
        }
      );
      // Handle response
      console.log("Response:", response.data);
      return response.data; // Return data if needed
    } catch (error) {
      console.log(error.message);
    }
  },
  getMedia: async (accessToken, dashboard, teamId) => {
    try {
      const response = await axios.get(`${Url}/customer/getMedia`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          teamId: teamId,
          dashboard: dashboard,
        },
      });
      // Handle response
      console.log("Response:", response.data);
      return response.data; // Return data if needed
    } catch (error) {
      console.log(error.message);
    }
  },
  uploadPdf: async (accessToken, dashboard, teamId, pdf) => {
    try {
      const formData = new FormData();
      formData.append("pdf", pdf);
      const response = await axios.post(`${Url}/customer/uploadPdf`, formData, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          teamId: teamId,
          dashboard: dashboard,
        },
      });
      // Handle response
      console.log("Response:", response.data);
      return response.data; // Return data if needed
    } catch (error) {
      console.log(error.message);
    }
  },
  getPdf: async (accessToken, dashboard, teamId) => {
    try {
      const response = await axios.get(`${Url}/customer/getPdf`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          teamId: teamId,
          dashboard: dashboard,
        }
      });

      // You can return any relevant data if needed
      console.log("api response", response);
      return response;
    } catch (error) {
      console.log("Error fetching PDF:", error.message);
      // Handle error accordingly
      throw error; // Optionally rethrow the error for further handling
    }
  },
  uploadCWCPdf: async (accessToken, dashboard, userId, pdf) => {
    try {
      const formData = new FormData();
      formData.append("pdf", pdf);
      const response = await axios.post(
        `${Url}/customer/uploadCWBPdf`,
        formData,
        {
          headers: {
            "access-token": accessToken,
          },
          params: {
            userId: userId,
            dashboard: dashboard,
          },
        }
      );
      // Handle response
      console.log("Response:", response.data);
      return response.data; // Return data if needed
    } catch (error) {
      console.log(error.message);
    }
  },
  getCWBPdf: async (accessToken, dashboard, userId) => {
    try {
      const response = await axios.get(`${Url}/customer/getCWBPdf`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          userId: userId,
          dashboard: dashboard,
        },
      });
      console.log("api response", response);
      // Convert the response blob to an array of individual blobs
      //   const pdfBlob = response.data;
      //   // Log the size and type of the blob
      //   console.log("Blob size:", pdfBlob.size);
      //   console.log("Blob type:", pdfBlob.type);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  },
  getAllCWBPdf: async (accessToken, dashboard, userId) => {
    try {
      const response = await axios.get(`${Url}/customer/getAllCWBPdf`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          userId: userId,
          dashboard: dashboard,
        },
      });
      console.log("api response", response.data);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  DownloadSelectedPdf: async (accessToken, pdfId, userId) => {
    try {
      const response = await axios.get(`${Url}/customer/DownloadSelectedPdf`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          pdfid: pdfId,
          userid: userId
        },
      });
      // Create a URL for the blob
    //   const url = window.URL.createObjectURL(response.data);
    //   // Create a link element
    //   const a = document.createElement('a');
    //   // Set the href attribute to the URL of the blob
    //   a.href = url;
    //   // Set the download attribute to specify the filename
    //   a.download = 'downloaded-file.pdf';
    //   // Append the link to the body
    //   document.body.appendChild(a);
    //   // Programmatically click the link to trigger the download
    //   a.click();
    //   // Remove the link from the DOM
    //   window.URL.revokeObjectURL(url);

      // You can return any relevant data if needed
      console.log("api response", response);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  },
  uploadProfileImage: async (accessToken, dashboard, userid, media) => {
    try {
      // const formData = new FormData();
      // formData.append("media", media);
      const response = await axios.post(
        `${Url}/customer/uploadProfilePicture`,
         media,
        {
          headers: {
            "access-token": accessToken,
          },
          params: {
            userid: userid,
            dashboard: dashboard,
          },
        }
      );
      // Handle response
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getProfilePicture: async (accessToken, userid) => {
    try {
      const response = await axios.get(`${Url}/customer/getProfilePicture`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          userid: userid,
        },
      });
      // Handle response
      console.log("Response:", response.data);
      return response.data; // Return data if needed
    } catch (error) {
      console.log(error.message);
    }
  },
};

export default mediaUploadApis;
