/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-06-16 20:49:53
 */
import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { AccountButton } from "../account-button";
import { SearchButton } from "../search-button";
import { createTypography } from "src/theme/base/customTypography";
import Divider from "@mui/material/Divider";

// import { saveAs } from "../../../index";
import { CircularProgress } from '@mui/material';
import * as SldButtons from "src/theme/base/SldButtons";
import { ReactComponent as DownloadPageIcon } from "src/icons/untitled-ui/downloadPage.svg";
import { useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate } from "react-router";
import { DownloadPagePopUp } from "src/layouts/leadselfDashboard/popUps/downloadPagePopUp";
import { useUser } from "src/hooks/use-user";
import { useTeam } from "src/contexts/team-context";
import teamDashboardResultsApi from "src/services/teamdashboardApi";
import { toast } from "react-hot-toast";

const TOP_NAV_HEIGHT = 85;
const SIDE_NAV_WIDTH = 250;

export const TopNav = (props) => {
  const user = useUser();
  const { selectedTeamId } = useTeam();
  const accessToken = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const navigateToExplore = () => {
    navigate("/explorerPage");
  };
  const typography = createTypography();
  const { onMobileNavOpen, toptitle, ...other } = props;
  console.log("top tiltle check", toptitle);
  const [isLoading, setIsLoading] = useState(false);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  // const getShareLink = async (type) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await saveAs(0, type, "Team Dashboard");
  //     setIsLoading(response);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  const isMobile = useMediaQuery("(max-width:600px)");

  let renderedTitle;
  switch (toptitle) {
    case "Team Mojo":
      renderedTitle = "Team Diagnostics";
      break;
    case "Team Leadership":
      renderedTitle = "Team Diagnostics";
      break;
    case "Team Diversity":
      renderedTitle = "Team Dynamics";
      break;
    case "Click-With":
      renderedTitle = "Team Dynamics";
      break;
    case "Team Compatability":
      renderedTitle = "Team Dynamics";
      break;
    case "Team Charter":
      renderedTitle = "Team Development";
      break;
    default:
      // eslint-disable-next-line
      renderedTitle = toptitle; // This line should set the default value
  }

  const checkTeamLeader = async () => {
    console.log("function is calles ---------");
    try {
      const response = await teamDashboardResultsApi.checkTeamLeader(selectedTeamId, user.id, accessToken);
      console.log("response", response);
      if(response.checkedTeamLeader){
        setPopupOpen(true);
        setIsLoading(false);
      }else {
        setIsLoading(false)
        toast.error("Only Team leaders are permitted to download and link sharing")
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  const [isPopupOpen, setPopupOpen] = useState(false);
  const openPopup = () => {
    checkTeamLeader();
    setIsLoading(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
  };
  return (
    <Box
      component="header"
       id="topNavWidth"
      sx={{
        backdropFilter: "blur(6px)",
        backgroundColor: '#f9f9f9',
        position: "sticky",
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="flex-end"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 6,
          marginBottom:1.5
        }}
      >
        <Stack alignItems="center"
          direction="row"
          spacing={1}
          sx={{ flexGrow: '2', }}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          <Typography 

            sx={typography.profileHeaing}>{toptitle}</Typography>
          <SearchButton />
        </Stack>
        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"

          spacing={0}>
          <SldButtons.WhiteButtonSIcon sx={{ flexWrap: 'wrap', display: 'flex' }}
            id="downloadButton"
            variant="outlined"
            onClick={openPopup}
            // onClick={() => getShareLink("pdf")}
            startIcon={isLoading ? <CircularProgress size={20} /> : <DownloadPageIcon />}
          >
            <span>{isMobile ? null : 'Download Page'}</span>
          </SldButtons.WhiteButtonSIcon>
        </Stack>
        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"

          spacing={0}>          <IconButton>
            <HelpOutlineIcon />
          </IconButton>
          <IconButton id="downloadButton2"
           onClick={navigateToExplore}>
            <LanguageIcon />
          </IconButton>
          <AccountButton />
        </Stack>
        
      </Stack><Divider sx={{mx:6}}/>
      <DownloadPagePopUp open={isPopupOpen}
        onClose={closePopup}
        surveyId={6}
        fileName={toptitle} />
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
