/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-02 00:13:28
 */
import React, { useEffect } from "react";
import { Backdrop, Checkbox, CircularProgress, FormControlLabel, FormGroup} from "@mui/material";
import { ReactComponent as SmartLeaderLogo } from "../../icons/untitled-ui/smartLeaderWhite.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// import SurveyTile from "./surveys/surveyCards";
import { RouterLink } from "src/components/router-link";
// import surveyAPIService from "src/services/surveyApi";
import surveyAPIService from "src/services/surveyApi";
import { useState } from "react";
import { createTypography } from "src/theme/base/customTypography";
import ExecutiveHealthSurveyTile from "./components/surveyCards";
import loginApiService from "src/services/loginAPI";
import * as SldButtons from "src/theme/base/SldButtons";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import LinkExpired from "../wiredUpSurveys/surveys/linkExpire";
// import { createTypography } from "../../theme/base/customTypography";




function ExecutiveHealthsurveyStartPageLayout(props) {
  const { children } = props;
  console.log("children", children);
  // const id = 7;
  const token = "tokenuser";
  // const sectionPage = 1;

  const accesssToken = sessionStorage.getItem("accessToken");
  const queryParameters = new URLSearchParams(window.location.search);
  const surveyId = queryParameters.get("surveyId");
  const [inviteId, setInviteId] = useState(null);
  const [isLoading ,setIsLoading] = useState(true);
  const emailToken = queryParameters.get("token");
  const emailInviteId= queryParameters.get("inviteId")
  const [isAcknowledge,setIsAcknowledge] = useState(false)
  const [valid, setValid] = useState(0);
  const CheckIsAcknowledge =()=>{
    setIsAcknowledge(!isAcknowledge)
  }
  /**
   * Function to get invite id , If no invite id is present new invite id will be created
   * @date 11/5/2023 - 12:07:16 AM
   *
   * @async
   * */
  const getInviteId = async () => {
    try {
      const inviteIdGetData = {
        survey_id: surveyId,
        inviteId: inviteId,
        timeStamp: "",
        teamId: "",
      };
      //api call to check invite id
      const response = await surveyAPIService.checkInviteId(
        inviteIdGetData,
        accesssToken
      );
      console.log("invite id", response);
      setInviteId(response.data.inviteId);
      setIsLoading(false)
    } catch (error) {}
  };
  const validateToken = async() =>{
    try {
      console.log('tokkenn validator called',token);
      const response = await loginApiService.decodeToken(emailToken);
      console.log('token fetched',response);
      if(response?.userId){
        const stringifiedUser = JSON.stringify(response)
      sessionStorage.setItem("userDetails", stringifiedUser);
      sessionStorage.setItem("accessToken",emailToken)
      setInviteId(emailInviteId);
      }
      

    } catch (error) {
      
    }
  }
  const validateLink = async () => {
    try {
      console.log('inviteId', inviteId);
      const response = await loginApiService.validateLink(emailToken, inviteId);
      console.log('response fetched', response);
      setValid(response[0].is_complete);
      setIsLoading(false)
    } catch (error) {

    }
  }
  useEffect(() => {
    if (inviteId && emailInviteId &&  emailToken) {
      validateLink();
    }
    // eslint-disable-next-line
  }, [inviteId,emailInviteId,emailToken])
  useEffect(()=>{
    if(emailInviteId &&  emailToken){
      console.log('---validate token use effect',emailInviteId,emailToken);
      validateToken()
    }
    else{
      getInviteId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[emailInviteId,emailToken])
  const typography = createTypography();
  // useEffect(()=>{
  //   if(inviteId){
  //     setIsLoading(false)
  //   }
  // },[inviteId])
  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  console.log("valid", valid);
  if (valid === 1) {
    return <LinkExpired />;
  }
  return (
<SldSurveyCard.SurveyGrid
      container
      spacing={0}
      
    >
      <SldSurveyCard.SurveyGridItem
        item
        lg={12}
        sm={12}
      >
        <SldSurveyCard.SurveyLogo>
          <SmartLeaderLogo width='250' />
        </SldSurveyCard.SurveyLogo>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        spacing={6}>
          <Typography sx={typography.qNheading}>
            My Executive Health Questionnaire
          </Typography>
          <SldButtons.SurveyClock 
            variant="contained">
          <AccessTimeIcon />
            <Typography variant="subtitle1"> Duration <span>20 mins</span></Typography>
          </SldButtons.SurveyClock>
          
        </Stack>
      </SldSurveyCard.SurveyGridItem>
      <SldSurveyCard.SurveyStack
    spacing={3}
    
    sx={{
      marginTop:{lg :'-140px', sm :'5px', xs :'3px'},
      padding:{lg:'0 60px', md:'0 60px', sm:'0 0', xs:'0'},
    }}
  >
      <ExecutiveHealthSurveyTile />
      <SldSurveyCard.SurveyRules 
      lg={12} 
     >
        <SldSurveyCard.SurveyContainer>{children} </SldSurveyCard.SurveyContainer>
        {/* </SurveyStack> */}
        <FormGroup sx={{alignItems : "center" , justifyContent:"center"}}>
          <FormControlLabel  required 
          control={<Checkbox onClick={()=>CheckIsAcknowledge()} />} 
          label="I Agree to share anonymous data for research purposes." />
        </FormGroup>
        {/* <Typography>Yes, I agree with you</Typography> */}
        <SldButtons.WhiteButtonCap disabled ={!isAcknowledge} 
        endIcon={<ArrowForwardIcon />}
            variant="contained"
            component={RouterLink}
            to={`/surveyQuestions?id=${surveyId}&inviteId=${inviteId}`}
          >
            <Typography sx={typography.startButton}>Start</Typography>
            </SldButtons.WhiteButtonCap> 
      </SldSurveyCard.SurveyRules >
      </SldSurveyCard.SurveyStack>
      </SldSurveyCard.SurveyGrid>
  );
}


export default ExecutiveHealthsurveyStartPageLayout;
