import React from 'react';
import { Box } from '@mui/material'; 
import { ReactComponent as NavigateStart } from "src/icons/untitled-ui/NavigateStart.svg"
import * as SldButtons from "src/theme/base/SldButtons";

const CustomBeacon = ({ step, onClick }) => {
  return (
    <Box 
      p={2} 
      bgcolor="#fff" 
      borderRadius={1} 
      boxShadow={2} 
      width={400}
      textAlign="center" 
    >
      <NavigateStart/>
      <div 
      style={{ position: 'relative' }}>
        <p 
        style={{ color:'#583f99' }}>Take the tour to discover new features and useful tips to learn more about your team and keep your finger on your team’s pulse.</p>
        <SldButtons.PurpleButton 
        sx={{ color:'#fff', padding:'3px' }} 
        onClick={onClick}>Next</SldButtons.PurpleButton>
      </div>
    </Box>
  );
};

export default CustomBeacon;
